import {Box, Container, Typography} from "@mui/joy";
import tg from "../assets/tg.png";

const Header = () => {
  return (
    <Box as={"header"} className={'bg-dark-600 py-3 text-white'}>
      <Box component={Container} display={'flex'} className={'gap-3 justify-center items-center'}>
        <img src={tg} alt={'Telegram logo'} className={'w-10 h-10'}/>
        <Typography level={'h3'} color={'white'}>
          Sólo para usuarios de telegram
        </Typography>
      </Box>
    </Box>
  );
};

export default Header;