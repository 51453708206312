import './App.css';
import {lazy} from "react";
import Header from "./components/Header";
import FixedButton from "./components/FixedButton";
import Hero from "./components/Hero";

const Reviews = lazy(() => import('./components/Reviews/Reviews'));
const VideoReviews = lazy(() => import('./components/VideoReviews'));

function App() {
  return (
    <>
      <Header/>
      <Hero/>
      <Reviews/>
      <VideoReviews/>
      <FixedButton/>
    </>
  )
}

export default App;
