import {Box, Button, Container} from "@mui/joy";
import tg from "../assets/tg.png";
import {useMediaQuery} from "react-responsive";

const FixedButton = () => {
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'})

  return (
    <Box className={'fixed w-full bottom-0 z-50 p-2'}>
      <Container>
        <Button
          variant="solid"
          size="lg"

          startDecorator={<img src={tg} alt={'Telegram'} className={'w-10 h-10'}/>}
          color='primary'
          className={'animate-bounce w-full hover:animate-none hover:transformX-0.5 hover:shadow-lg transition-all ease-in-out'}
          sx={{
            paddingY: isTabletOrMobile ? '1rem' : '1.1rem',
            paddingX: isTabletOrMobile ? '1rem' : '2rem',
            fontSize: isTabletOrMobile ? '1rem' : '2rem',
          }}
        >
          Suscríbete a mi canal de telegram
        </Button>
      </Container>
    </Box>
  );
};

export default FixedButton;