import {CircularProgress, Container} from "@mui/joy";
import {lazy} from "react";
import video from "../assets/video.mp4";
const Video = lazy(() => import("./Video"));

const Hero = () => {
  return (
    <Container sx={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
    }} className={'text-white text-center py-16 gap-2'}>
      <div
        className={'flex-auto'}>
        <Video video={video} circle={true} pulse={true} loading={<CircularProgress />}/>

      </div>
      <h5 className={"text-xl lg:text-3xl"}>
        Únase al arbitraje criptográfico de élite
      </h5>
      <h5 className={"text-2xl lg:text-4xl"}>
        Grupo de señales
      </h5>
      <h1 className={"text-4xl lg:text-6xl font-black"}>
        Gane $1000~$3000+ cada día que prometemos
      </h1>
      <p className={'text-2xl text-accent-500'}>
        ...Incluso si "eres nuevo en las criptomonedas" Incluso si "eres nuevo en bitcoins"...
      </p>

    </Container>

  );
};

export default Hero;